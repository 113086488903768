<template>
    <div>
        <div class="row justify-content-center">
           <div class="col-12 p-4">
               <button type="button" @click="$router.go(-1)" class="btn btn-sm btn-style4 text-xs"> <span class="typcn typcn-arrow-left"></span> Back</button>
           </div>
          <div class="col-md-11" style="min-height:100vh;">
              <!-- <button type="button" @click="$router.go(-1)" class="btn btn-sm btn-dark  my-3">Back</button> -->
              <!-- <div class="sm-form ">
                  <label for="search">Cari tanggal</label>
                  <button type="button" class="btn ml-3 btn-sm btn-dark  " @click="pencarian=''"><span class="typcn typcn-arrow-loop"></span></button>
                  <input type="date" style="width:300px;" id="search" name="search" class="form-control p-2 rounded-lg shadow" placeholder="search" v-model="pencarian" >
              </div> -->
              <div class="row">
                  <div class="col-6">
                      <input type="text" id="pencarian" name="pencarian" class="form-control p-2 rounded-lg shadow" placeholder="pencarian nota" v-model="pencarian"  >
                  </div>
                  <div class="col-6">
                      <input type="date" id="pencariantgl" name="pencariantgl" class="form-control p-2 rounded-lg shadow" placeholder="pencariantgl" v-model="pencariantgl"  >
                  </div>
              </div>
              <hr class="my-2">
              <div class="shadow rounded-lg p-3" v-if="pelanggan.length>0">
                    <hr class="my-2">
                    <div v-if="td.length>0">
                            <div v-for="(item, index) in td" :key="index+'history'" class="my-2 shadow rounded p-3">
                                <div class="row">
                                <div class="col-12 py-3">
                                    <p class="text-lg float-right" >Tanggal : <span class="typcn typcn-calendar"></span> {{format(item.tanggal)}}</p>
                                    <button type="button" class="btn btn-sm btn-dark">{{index+1}}</button>
                                    <hr class="my-2">
                                </div>
                                <div class="col-6">
                                    <div class="shadow p-3 rounded">
                                        <p class="text-lg font-semibold">Staff yang menangani : </p>
                                        <p class="text-xs font-semibold">{{item.id_transaksi_nota}}</p>
                                        <hr class="my-2">
                                        <table>
                                            <tr v-if="item.dokter">
                                                <td>Dokter</td>
                                                <td class="px-2">:</td>
                                                <td>{{item.dokter}}</td>
                                            </tr>
                                            <tr v-if="item.nurse">
                                                <td>Nurse</td>
                                                <td class="px-2">:</td>
                                                <td>{{item.nurse}}</td>
                                            </tr>
                                            <tr v-if="item.facial">
                                                <td>Facial</td>
                                                <td class="px-2">:</td>
                                                <td>{{item.facial}}</td>
                                            </tr>
                                            <tr v-if="item.apoteker">
                                                <td>Apoteker</td>
                                                <td class="px-2">:</td>
                                                <td>{{item.apoteker}}</td>
                                            </tr>
                                            <tr v-if="item.admin">
                                                <td>Admin/Kasir</td>
                                                <td class="px-2">:</td>
                                                <td>{{item.admin}}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="shadow p-3 rounded font-semibold">
                                        <p class="text-xs">Pelanggan: {{pelanggan[0].nama}}</p>
                                        <p class="text-xs" >Tanggal : {{format(item.tanggal)}}</p>
                                        <hr class="my-3">
                                    </div>
                                </div>
                            </div>
                            <hr class="my-3">
                            <div class="row">
                            <div class="col-md-6 " v-if="item.keluhan && item.objective">
                                <div class="shadow p-2 rounded-lg">
                                    <hr class="my-2">
                                    <div class="sm-form">
                                        <div>
                                            <div v-if="item.keluhan">
                                                <p>Keluhan : </p>
                                                <p v-text="item.keluhan" class="font-semibold"></p>
                                                <hr class="my-2">
                                            </div>
                                            <div v-if="item.objective">
                                            <p>Objective : </p>
                                            <p v-text="item.objective" class="font-semibold"></p>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            <div class="col-md-6 shadow p-2 rounded-lg" v-if="item.diagnosis && item.plan && item.plan_catatan">
                                <div class="shadow p-2 rounded-lg">
                                    <hr class="my-2">
                                    <div>
                                        <div v-if="item.diagnosis">
                                            <p class="">Diagnosis : </p>
                                            <p v-text="item.diagnosis" class="font-semibold"></p>
                                            <hr class="my-2">                           
                                        </div>
                                        <div v-if="item.plan">
                                            <p class="">Plan : </p>
                                            <p v-text="item.plan" class="font-semibold"></p>
                                            <hr class="my-2">
                                        </div>
                                        <div v-if="item.plan_catatan">
                                            <p>Catatan : </p>
                                            <p v-text="item.plan_catatan" class="font-semibold"></p>
                                            <hr class="my-2">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <hr class="my-3">
                            <div class="p-2">
                                <div class="row">
                                    <div class="col-6" v-if="item.catatan_admin">
                                        <p class="my-2"><span class="typcn typcn-document-text"></span> Catatan Nama_admin</p>
                                        <div class="shadow p-2 rounded-lg font-semibold pl-4">
                                            <div v-html="item.catatan_admin"></div>
                                        </div>
                                        <hr class="my-2">
                                    </div>
                                    <div class="col-6" v-if="item.catatan_dokter">
                                        <p class="my-2"><span class="typcn typcn-document-text"></span> Catatan Nama_dokter</p>
                                        <div class="shadow p-2 rounded-lg font-semibold pl-4">
                                            <div v-html="item.catatan_dokter"></div>
                                        </div>
                                        <hr class="my-2">
                                    </div>
                                    <div class="col-6" v-if="item.catatan_nurse">
                                        <p class="my-2"><span class="typcn typcn-document-text"></span> Catatan Nama_nurse</p>
                                        <div class="shadow p-2 rounded-lg font-semibold pl-4">
                                            <div v-html="item.catatan_nurse"></div>
                                        </div>
                                        <hr class="my-2">
                                    </div>
                                    <div class="col-6" v-if="item.catatan_facial">
                                        <p class="my-2"><span class="typcn typcn-document-text"></span> Catatan Nama_facial</p>
                                        <div class="shadow p-2 rounded-lg font-semibold pl-4">
                                            <div v-html="item.catatan_facial"></div>
                                        </div>
                                        <hr class="my-2">
                                    </div>
                                    <div class="col-6" v-if="item.catatan_apoteker">
                                        <p class="my-2"><span class="typcn typcn-document-text"></span> Catatan Nama_apoteker</p>
                                        <div class="shadow p-2 rounded-lg font-semibold pl-4">
                                            <div v-html="item.catatan_apoteker"></div>
                                        </div>
                                        <hr class="my-2">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <hr>
                        <p class="p-5 text-center">Catatan Kosong </p>
                    </div>
              </div>
          </div>
        </div>
    </div>
</template>
<script>
// ganti source autophp, ganti source component btable, ganti path table sdb
import autophp from "@/plugins/autophp_kasir.js"; // ganti source nya
let sdb = new autophp();
export default{
    data(){
        return{
            pelanggan:[],
            history:[],
            transaksi:[],
             dokter:[],
            nurse:[],
            adminapotek:[],
            apoteker:[],
            pencarian:'',
            pencariantgl:'',
        }
    },
    computed:{
        td(){
            let that=this;
            let data=this.history;
            data=data.filter(e=>{
                if(e.id_transaksi_nota.indexOf(that.pencarian)!=-1 && e.tanggal.indexOf(that.pencariantgl)!=-1){
                    return e;
                }
            })
            console.log(data)
            return data;
        }
    },
    methods: {
        format(tgl) {
            return this.$datefns.format(
                new Date(tgl),
                "DD-MM-YYYY"
            );
        },
        getData(){
            let that=this;
            let sql =`select 
            tb1.*,tb2.id_admin,tb2.id_dokter,tb2.id_apoteker,tb2.id_nurse,
            tb2.catatan_admin,tb2.catatan_apoteker,tb2.catatan_dokter,tb2.catatan_facial,tb2.catatan_nurse,
            tb3.nama as admin,
            tb4.nama as dokter,
            tb5.nama as nurse,
            tb6.nama as apoteker,
            tb7.nama as facial
            from app_kasir_pelanggan_history tb1 
            left join app_kasir_transaksi_nota tb2 on tb2.nota=tb1.id_transaksi_nota
            left join tbuser tb3 on tb3.id=tb2.id_admin 
            left join tbuser tb4 on tb4.id=tb2.id_dokter 
            left join tbuser tb5 on tb5.id=tb2.id_nurse 
            left join tbuser tb6 on tb6.id=tb2.id_apoteker 
            left join tbuser tb7 on tb7.id=tb2.id_facial 
            where tb1.pelanggan='${this.$route.query.pelanggan}' AND tb1.catatan IS NOT NULL`;
            console.log(sql)
            sdb.collection("app_kasir_pelanggan_history",false).doc().select(sql).then(res => {
                console.log('history',res)
                this.history=res;
                this.history= _.orderBy(this.history, ['tanggal'],['desc']);
                this.$forceUpdate();
            });
            sdb.collection("app_kasir_pelanggan",false).doc().select(`select * from app_kasir_pelanggan where id='${this.$route.query.pelanggan}'`).then(res => {
                this.pelanggan=res;
                this.$forceUpdate();
            });
        }
    },
    layout:'app_shop_admin',
    mounted() {
        this.getData();
    },
}
</script>